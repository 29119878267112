/**
 * Use this file for JavaScript code that you want to run in the front-end
 * on posts/pages that contain this block.
 *
 * When this file is defined as the value of the `viewScript` property
 * in `block.json` it will be enqueued on the front end of the site.
 *
 * Example:
 *
 * ```js
 * {
 *   "viewScript": "file:./view.js"
 * }
 * ```
 *
 * If you're not making any changes to this file because your project doesn't need any
 * JavaScript running in the front-end, then you should delete this file and remove
 * the `viewScript` property from `block.json`.
 *
 * @see https://developer.wordpress.org/block-editor/reference-guides/block-api/block-metadata/#view-script
 */
 
document.addEventListener('DOMContentLoaded', function() {
    document.querySelectorAll('.ventrix-rankings-block').forEach(function(block) {

        // Set variables
        const aboutButton = block.querySelector('.rankings-top-bar--about');
        const popup = block.querySelector('.rankings-popup--widget');
        const closeButton = block.querySelector('.rankings-popup--widget--close');
        const overlay = block.querySelector('.rankings-popup--overlay');
        const expandAllButton = block.querySelector('.expand-all');
        const collapseAllButton = block.querySelector('.collapse-all');

        // Get defaultOpen from the data attribute
        const defaultOpen = parseInt(block.getAttribute('data-default-open')) || 3;

        // Popup functionality
        if (aboutButton) {
            aboutButton.addEventListener('click', function() {
                popup.classList.remove('hidden');
                overlay.classList.remove('hidden');
            });
        }

        if (closeButton) {
            closeButton.addEventListener('click', function() {
                popup.classList.add('hidden');
                overlay.classList.add('hidden');
            });
        }

        if (overlay) {
            overlay.addEventListener('click', function() {
                popup.classList.add('hidden');
                overlay.classList.add('hidden');
            });
        }

        document.addEventListener('keydown', function(event) {
            if (event.key === 'Escape') {
                popup.classList.add('hidden');
                overlay.classList.add('hidden');
            }
        });

        // Accordion functionality
        block.querySelectorAll('.rankings-list--item').forEach(function(item, index) {
            var content = item.querySelector('.rankings-list--item--hidden');
            var leftToggleButton = item.querySelector('.rankings-list--item--heading--left--button');
            var rightToggleButton = item.querySelector('.rankings-list--item--heading--right--button');
            
            if (content) {
                item.addEventListener('click', function() {
                    content.classList.toggle('hidden');
                    if (content.classList.contains('hidden')) {
                        if (leftToggleButton) leftToggleButton.classList.remove('expanded');
                        if (rightToggleButton) rightToggleButton.classList.remove('expanded');
                    } else {
                        if (leftToggleButton) leftToggleButton.classList.add('expanded');
                        if (rightToggleButton) rightToggleButton.classList.add('expanded');
                    }
                });

                // Expand the first `defaultOpen` items
                if (index < defaultOpen) {
                    content.classList.remove('hidden');
                    if (leftToggleButton) leftToggleButton.classList.add('expanded');
                    if (rightToggleButton) rightToggleButton.classList.add('expanded');
                }
            }
        });

        // Expand/Collapse All functionality
        if (expandAllButton) {
            expandAllButton.addEventListener('click', function() {
                // Switch button classes
                expandAllButton.classList.add('btn-active');
                expandAllButton.classList.remove('btn-inactive');
                collapseAllButton.classList.add('btn-inactive');
                collapseAllButton.classList.remove('btn-active');

                // Expand all elements
                block.querySelectorAll('.rankings-list--item .rankings-list--item--hidden').forEach(function(element) {
                    element.classList.remove('hidden');
                    const leftToggleButton = element.closest('.rankings-list--item').querySelector('.rankings-list--item--heading--left--button');
                    const rightToggleButton = element.closest('.rankings-list--item').querySelector('.rankings-list--item--heading--right--button');
                    if (leftToggleButton) leftToggleButton.classList.add('expanded');
                    if (rightToggleButton) rightToggleButton.classList.add('expanded');
                });
            });
        }

        if (collapseAllButton) {
            collapseAllButton.addEventListener('click', function() {
                // Switch button classes
                collapseAllButton.classList.add('btn-active');
                collapseAllButton.classList.remove('btn-inactive');
                expandAllButton.classList.add('btn-inactive');
                expandAllButton.classList.remove('btn-active');

                // Collapse all elements
                block.querySelectorAll('.rankings-list--item .rankings-list--item--hidden').forEach(function(element) {
                    element.classList.add('hidden');
                    const leftToggleButton = element.closest('.rankings-list--item').querySelector('.rankings-list--item--heading--left--button');
                    const rightToggleButton = element.closest('.rankings-list--item').querySelector('.rankings-list--item--heading--right--button');
                    if (leftToggleButton) leftToggleButton.classList.remove('expanded');
                    if (rightToggleButton) rightToggleButton.classList.remove('expanded');
                });
            });
        }
    
    });
});